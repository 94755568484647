import Api from './Api'

export default {
    login(credentials) {
        return Api().post('users/login', credentials);
    },
    associarUser(credentials) {
        return Api().post('users/associarAPI', credentials);
    },
    login_API_SBU(credentials) {
        const headers = {
            'Authorization': `Bearer ${process.env.VUE_APP_API_SBU_SP}` // Substitua pelo seu token de autenticação
        };
        return Api().post('https://siasbu.org.br/unimagem-service/authentication', credentials, { headers });
    },
    getUser(){
        return Api().get('users/profile');
    },
    create(credentials){
        return Api().post('users/register', credentials);
    },
    update(credentials){
        return Api().put('users/update', credentials);
    },
    newPassVerifyEmail(email){
        return Api().post(`users/newPassword/verify-email`, email );
    },
    newPassVerifyToken(token){
        return Api().post(`users/newPassword/verify-token`, token);
    },
    newPass(credentials){
        return Api().post(`users/newPassword`, credentials );
    },
    updateProfileImage(image){
        return Api().post(`users/upload-image`, image );
    }
}