// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import email from './modules/email';
import navbar from './modules/navbar';
import posts from './modules/posts';
import category from './modules/category';
import address from './modules/address';

// Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        email,
        navbar,
        posts,
        category,
        address
    }
})
